import React from "react";
import arrowIcon from "../../images/arrow.svg";
import logo from "../../images/smartzee-logo.svg";
import zeeGrayscale from "../../images/zee-icon-grayscale.svg";
import "./FifthComponent.css";

export default (props) => {
  return (
    <div
      className='component fifth-component'
      style={{ backgroundImage: `url(${zeeGrayscale})` }}>
      <div className='row' style={{ width: "100%" }}>
        <div className='col'>
          <div className='content-wrapper'>
            <div className='last-slide-header'>
              <h1 className='big-header'>JOIN NOW!</h1>
              <img className='last-slide-logo' src={logo} alt='logo' />
            </div>
            <a
              href='https://app.smartzee.com?action=signup'
              className='cta-button flex-it'>
              Register Now
            </a>
            <div className='subtext-wrapper'>
              Or contact us at{" "}
              <a className='subtext-link' href='mailto:info@smartzee.com'>
                info@smartzee.com
              </a>{" "}
              for more details and subscription plans.
            </div>
          </div>
        </div>
      </div>
      <div className='arrow-wrapper'>
        <span className='arrow-text'>Back to top</span>
        <img
          onClick={() => props.handlePageChange(0)}
          className='arrow-icon'
          src={arrowIcon}
          alt='arrow-icon'
        />
      </div>
    </div>
  );
};
