import React from "react";
import arrowIcon from "../../images/arrow.svg";
import logo from "../../images/smartzee-logo.svg";
import headerImg from "../../images/first-slide-header-image.png";
import "./FirstComponent.css";

export default (props) => {
  return (
    <div
      className='component first-component'
      tabIndex='0'
      ref={(input) => input && input.focus()}>
      <div className='row' style={{ width: "100%" }}>
        <div className='col col-6 left'>
          <div className='left-content-wrapper'>
            <img className='first-slide-logo' src={logo} alt='logo' />
            <h3 className='first-slide-header-title'>
              Your all-in-one cloud platform!
            </h3>
            {props.isMobile && (
              <img
                className='first-slide-header-image-mob'
                src={headerImg}
                alt='header-image'
              />
            )}
            <div className='register-now-wrapper'>
              <a
                href='https://app.smartzee.com/?action=signup'
                className='cta-button flex-it'>
                Register Now
              </a>
              <div className='subtext-wrapper'>
                Already a SmartZee member?{" "}
                <a href='https://app.smartzee.com/' className='subtext-link'>
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='col col-6 right flex-it'>
          <img
            className='first-slide-header-image'
            src={headerImg}
            alt='header-image'
          />
        </div>
      </div>
      <div className='arrow-wrapper'>
        <img className='arrow-icon' src={arrowIcon} alt='arrow-icon' />
        <span className='arrow-text'>Scroll down to know more</span>
      </div>
    </div>
  );
};
