import React from "react";
import slide2Image from "../../images/second-slide-image.png";
import arrowIcon from "../../images/arrow.svg";
import "./SecondComponent.css";

export default () => {
  return (
    <div className='component second-component'>
      <div className='row' style={{ width: "100%" }}>
        <div className='col col-6 left'>
          <div className='left-content-wrapper'>
            <h1 className='big-header'>What is SmartZee?</h1>
            <p>
              A cloud platform that gathers all your system devices all under
              one place. You can:
            </p>
            <ul>
              <li>
                Add, monitor and control your SmartZee devices from discrete
                locations from the cloud.
              </li>
              <li>
                Define your child smart meters under each ZEE device, monitor
                your PV system power/energy readings and control your energy
                import/export.
              </li>
              <li>
                Add your discrete standalone smart meters and monitor their
                readings from the cloud.
              </li>
              <li>
                Remotely control your system’s energy import/export from full
                export to zero export ( feed-in to grid ) based on power
                setpoint, time schedule, voltage or frequency range, or simply
                switch to your local config with a single button click.
              </li>
            </ul>
          </div>
        </div>
        <div className='col col-6 right flex-it'>
          <img
            className='first-slide-header-image second-slide-header-image'
            src={slide2Image}
            alt='header-image'
          />
        </div>
      </div>
      <div className='arrow-wrapper'>
        <img className='arrow-icon' src={arrowIcon} alt='arrow-icon' />
        <span className='arrow-text'>Scroll down to know more</span>
      </div>
    </div>
  );
};
