import React, { Fragment, useState } from "react";
import { Pager } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import ReactPageScroller, { SectionContainer } from "react-page-scroller";
import { Scrollbars } from "react-custom-scrollbars";
import FirstComponent from "./components/FirstComponent/FirstComponent";
import SecondComponent from "./components/SecondComponent/SecondComponent";
import ThirdComponent from "./components/ThirdComponent/ThirdComponent";
import FourthComponent from "./components/FourthComponent/FourthComponent";
import FifthComponent from "./components/FifthComponent/FifthComponent";

import "./index.css";

export default () => {
  const [currentPage, setCurrentPage] = useState(null);
  const [theme, setTheme] = useState("dark");

  const isDesktop = useMediaQuery({ minWidth: 1366 });

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1365 });

  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  const handleBeforePageChange = (number) => {
    // console.log(number);
  };

  const getPagesNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= 5; i++) {
      pageNumbers.push(
        <Pager.Item key={i} eventKey={i - 1} onSelect={handlePageChange}>
          {i}
        </Pager.Item>
      );
    }

    return [...pageNumbers];
  };

  const pagesNumbers = getPagesNumbers();

  return (
    <Fragment>
      <Themer theme={theme} />
      {isDesktop ? (
        <Fragment>
          <ReactPageScroller
            pageOnChange={handlePageChange}
            onBeforePageScroll={handleBeforePageChange}
            customPageNumber={currentPage}>
            <FirstComponent />
            {/* Half height component */}
            {/* <SectionContainer height={50}>
            <SecondComponent />
          </SectionContainer> */}
            <SecondComponent />
            <ThirdComponent />
            {/* <FourthComponent /> */}
            <FifthComponent handlePageChange={handlePageChange} />
          </ReactPageScroller>
          <Pager className='pagination-additional-class' bsSize='large'>
            {pagesNumbers}
          </Pager>
        </Fragment>
      ) : (
        <Fragment>
          <Scrollbars
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  backgroundColor: "gray",
                  borderRadius: "30px",
                }}
              />
            )}>
            <FirstComponent isMobile={isMobile} />
            <SecondComponent />
            <ThirdComponent />
            <FifthComponent handlePageChange={handlePageChange} />
          </Scrollbars>
        </Fragment>
      )}
    </Fragment>
  );
};

const Themer = ({ theme }) => {
  return (
    <style>{`
    :root {
      --color-bg: ${theme === "light" ? "#EFEFEF" : " #202B33"};
      --color-primary: ${theme === "light" ? "#f7931e" : " #f7931e"};
      --color-secondary: ${theme === "light" ? "#F9CF38" : " #F9CF38"};
      --color-shadows: ${theme === "light" ? "#FFFFFF" : " #161E23"};
      --color-borders-lines: ${theme === "light" ? "#4A4E5C" : " #4A4E5C"};
      --color-border-circle: ${theme === "light" ? "#FFFFFF" : " #E1E1E1"};
      --color-dark-text: ${theme === "light" ? "#202B33" : " #6F7281"};
      --color-light-text: ${theme === "light" ? "#6F7281" : " #A4A5AA"};
      --color-lighter-text: ${theme === "light" ? "#202B33" : " #EFEFEF"};
`}</style>
  );
};
