import React from "react";
import arrowIcon from "../../images/arrow.svg";
import feat1Img from "../../images/feat-rt-mon.png";
import feat2Img from "../../images/feat-import-export-control.png";
import feat3Img from "../../images/feat-toggle-config.png";
import feat4Img from "../../images/feat-dashboard.png";
import feat5Img from "../../images/feat-secure-data.png";
import "./ThirdComponent.css";

export default () => {
  return (
    <div className='component third-component'>
      <div className='row' style={{ width: "100%" }}>
        <div className='col'>
          <div className='content-wrapper'>
            <h1 className='big-header'>SmartZee Features</h1>
            <div className='card-container'>
              <ul className='cards'>
                <li className='card'>
                  <div className='card-wrapper'>
                    <img
                      className='card-img'
                      src={feat1Img}
                      alt='feature-rt-mon'
                    />
                    <h3 className='card-title'>Real-time status monitoring</h3>
                    <div className='card-content'>
                      <p>
                        Monitor devices online status in real-time, with all
                        your devices monitored from cloud.
                      </p>
                    </div>
                  </div>
                </li>
                <li className='card'>
                  <div className='card-wrapper'>
                    <img
                      className='card-img'
                      src={feat2Img}
                      alt='feature-rt-mon'
                    />
                    <h3 className='card-title'>
                      Remote control your device import/export
                    </h3>
                    <div className='card-content'>
                      <p>
                        Remotely control your devices import/export mode from
                        full export to zero export (feed-in to grid) based on
                        power setpoint, time schedule, voltage or frequency
                        range.
                      </p>
                    </div>
                  </div>
                </li>
                <li className='card'>
                  <div className='card-wrapper'>
                    <img
                      className='card-img'
                      src={feat3Img}
                      alt='feature-rt-mon'
                    />
                    <h3 className='card-title'>
                      Instantly switch between local and remote configs
                    </h3>
                    <div className='card-content'>
                      <p>
                        With a single click, switch between local device config
                        (on-site) and remote config (cloud).
                      </p>
                    </div>
                  </div>
                </li>
                <li className='card'>
                  <div className='card-wrapper'>
                    <img
                      className='card-img'
                      src={feat4Img}
                      alt='feature-rt-mon'
                    />
                    <h3 className='card-title'>Modern interactive dashboard</h3>
                    <div className='card-content'>
                      <p>
                        Monitor your devices real-time, historical and
                        cumulative data via a modern interactive dashboard that
                        feature powerful multi-capability power and energy
                        charts.
                      </p>
                    </div>
                  </div>
                </li>
                <li className='card'>
                  <div className='card-wrapper'>
                    <img
                      className='card-img'
                      src={feat5Img}
                      alt='feature-rt-mon'
                    />
                    <h3 className='card-title'>
                      Your sensitive data is secure and safe
                    </h3>
                    <div className='card-content'>
                      <p>
                        Secure connections and login process, so your sensitive
                        data and personal info are always protected.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='arrow-wrapper'>
        <img className='arrow-icon' src={arrowIcon} alt='arrow-icon' />
        <span className='arrow-text'>Scroll down to know more</span>
      </div>
    </div>
  );
};
